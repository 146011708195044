<template>
  <div class="home">
    <CommonHeader title="申请售后" />
    <div class="product-bai">
      <div class="t ft14 fw500 pt32">售后货品信息</div>
      <div class="product">
        <div
          class="item flex align-items-c"
          v-for="(m, i) in productOrder.orderProduct"
          :key="`pro${i}`"
        >
          <div class="img">
            <img
              :src="m.cart_info.product.image + '!120a'"
              alt=""
            >
          </div>
          <div class="desc">
            <div class="name fw500 ft12"><span v-if="m.cart_info.product.lot" class="mr5">LOT{{ m.cart_info.product.lot }} </span>{{ m.cart_info.product.store_name }}</div>
            <div class="price">
              <span>落槌价 <b>{{ formatPriceNumber(Number(m.product_price), 2) }}</b></span>
              <span>佣金 <b>{{ formatPriceNumber(Number(m.commission_price), 2) }}</b></span>
              <span v-if="productOrder.status == 1">
                <template v-if="m.cart_info.product.postage_type == 2">运费 {{ formatPriceNumber(Number(m.cart_info.product.postage_price), 2) }}</template>
                <template v-else-if="m.cart_info.product.postage_type == 3">包邮</template>
                <template v-else-if="m.cart_info.product.postage_type == 4">到付</template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="refund">
      <div class="t ft14 fw500 pt32">售后申请</div>
      <div class="apply-content">
        <div class="item flex align-items-c">
          <div class="l">售后类型</div>
          <div class="c flex">
            <div
              class="chiose"
              @click="setRefundType(1)"
            >
              <img
                v-if="refund_type == 1"
                src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
                alt=""
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
                alt=""
              >
              <div>仅退款</div>
            </div>
            <div
              v-if="productOrder.status == 2"
              class="chiose"
              @click="setRefundType(2)"
            >
              <img
                v-if="refund_type == 2"
                src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
                alt=""
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
                alt=""
              >
              <div>退货退款</div>
            </div>
          </div>
        </div>
        <div class="item flex align-items-c">
          <div class="l">最高可退</div>
          <div class="c">
            <div class="inp"><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{ formatPriceNumber(refund_price, 2) }}</div>
          </div>
        </div>
        <div
          class="item flex align-items-c"
          @click="showChangeCauseAction"
        >
          <div class="l">退款原因</div>
          <div class="c">
            <div :class="{ 'color-gray': !refund_message }">{{ refund_message ? refund_message : '请选择退款原因' }}</div>
          </div>
          <div class="r flex align-items-c">
            <img
              src="https://artimg2.artart.cn/image/20220219/86c43e7fab3564d61750317942dc0d83.png"
              alt=""
            >
          </div>
        </div>
        <div class="item flex">
          <div class="l">补充说明</div>
          <div class="c">
            <div class="inp">
              <textarea v-model="mark"></textarea>
            </div>
          </div>
        </div>
        <div class="item flex">
          <div class="l">上传图片</div>
          <div class="c flex align-items-c flex-wrap">
            <template v-if="pics.length">
              <div
                class="upload-view item-flex-center"
                v-for="(m, i) in pics"
                :key="`pic${i}`"
              >
                <img
                  class="img"
                  :src="m + '!m640'"
                >
                <div
                  class="del item-flex-center"
                  @click="delImgAction(i)"
                >
                  <img
                    src="https://artimg2.artart.cn/image/20220222/0d2666e16f29d990aefe413f82cfad6b.png"
                    alt=""
                  >
                </div>
              </div>
            </template>
            <van-uploader
              multiple
              :after-read="modeUpload"
              :max-count="9"
              v-if="pics.length < 9"
            >
              <div class="upload-view item-flex-center">
                <img
                  class="icon"
                  src="https://artimg2.artart.cn/image/20220222/7c7e5a02eb960041b7f6011955b6d971.png"
                >
              </div>
            </van-uploader>
          </div>
        </div>
      </div>
    </div>
    <div
      class="btn"
      :style="rightBtnStyle"
      @click="saveRefundAction"
    >提交申请</div>
    <van-action-sheet
      v-model:show="showChangeCause"
      :actions="refundCauseArray"
      cancel-text="取消"
      close-on-click-action
      @select="changeOk"
    />
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Toast, ActionSheet, Uploader } from "vant";
import { getOssUploadConfig } from "@/api/system";
import { formatPriceNumber, uploadImg } from "@/utils/util";
import { applyRefund } from "@/api/order";
import OSS from "ali-oss";

export default {
  components: {
    [ActionSheet.name]: ActionSheet,
    [Uploader.name]: Uploader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const productOrder = ref({});
    const ossClient = ref(null);
    const refund_type = ref(1);
    const refund_price = ref(""); // 退款金额
    const refundCauseArray = ref([
      { name: "请选择退款原因" },
      { name: "多拍、错拍、不想要" },
      { name: "协商一致退款" },
      { name: "发错货" },
      { name: "其他" },
    ]); // 退款原因列表
    const showChangeCause = ref(false);
    const refund_message = ref(""); // 退款原因
    const mark = ref(""); // 其他说明
    const pics = ref([]);

    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });
    
    onBeforeMount(() => {
      const item = localStorage.getItem("refundProduct");
      if (item) {
        console.log(JSON.parse(item));
        const order = JSON.parse(item);
        productOrder.value = JSON.parse(item);
        refund_price.value =
          order.status == 1
            ? order.pay_price
            : order.pay_price - order.total_postage;

        getOssUploadConfig({ type: 1 })
          .then((res) => {
            ossClient.value = new OSS({
              region: res.data.region,
              accessKeyId: res.data.Credentials.AccessKeyId,
              accessKeySecret: res.data.Credentials.AccessKeySecret,
              stsToken: res.data.Credentials.SecurityToken,
              bucket: res.data.bucket,
            });
          })
          .catch((message) => Toast(message));
      } else {
        Toast("参数错误");
        router.back();
        return false;
      }
      // productOrder =
    });

    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    const showChangeCauseAction = () => {
      showChangeCause.value = true;
    };

    const changeOk = (data) => {
      refund_message.value = data.name;
    };

    const modeUpload = (file) => {
      // 上传
      const index = pics.value.length;
      if (file.file) {
        uploadAction(file.file, index + 1 > 9 ? 9 : index + 1);
      } else {
        file.forEach((f, i) => {
          // console.log(f);
          uploadAction(f.file, index + i > 9 ? 9 : index + i);
        });
      }
      // const f = file.file;
    };
    const uploadAction = (f, index) => {
      if (f.type.indexOf("image") === -1) {
        return;
      }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      uploadImg(f, ossClient, (result) => {
        if (result.data.status == 200) {
          if (pics.value.length < 9) {
            pics.value.push(result.data.data.image_url);
          }
          if (!index || index >= pics.value.length) {
            Toast.clear();
          }
        } else {
          Toast.clear();
          Toast(result.data.message);
        }
      });
    };

    const delImgAction = (index) => {
      // 删除图片
      pics.value.splice(index, 1);
    };

    const setRefundType = (val) => {
      // 选择退款类型
      if (val !== refund_type.value) {
        refund_type.value = val;
      }
    };
    let lock = false;
    const saveRefundAction = () => {
      // 提交申请
      if (!refund_message.value) {
        Toast("请选择退款原因");
        return false;
      }
      if (lock) return false;
      lock = true;
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      const ids = productOrder.value.orderProduct.map(
        (m) => m.order_product_id
      );
      applyRefund(productOrder.value.order_id, {
        refund_type: refund_type.value,
        order_product_id: ids.join(","),
        refund_message: refund_message.value,
        refund_price:
          productOrder.value.status == 1
            ? productOrder.value.pay_price
            : productOrder.value.pay_price - productOrder.value.total_postage,
        refund_num: 1,
        pics: pics.value,
        mark: mark.value,
      })
        .then(() => {
          Toast.clear();
          Toast("申请成功");
          setTimeout(() => {
            lock = false;
            router.back();
          }, 1000);
        })
        .catch((message) => {
          Toast.clear();
          Toast(message);
          lock = false;
        });
    };

    return {
      productOrder,
      colorData,
      rightBtnStyle,
      refund_type,
      refund_price,
      refundCauseArray,
      showChangeCause,
      refund_message,
      mark,
      pics,
      merchantInfo,

      formatPriceNumber,
      changeOk,
      showChangeCauseAction,
      modeUpload,
      setRefundType,
      saveRefundAction,
      delImgAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f7f7f7;
  padding-bottom: 200px;
  .t {
    padding: 64px 32px 32px 32px;

    &.pt32 {
      padding-top: 32px;
    }

    span {
      font-weight: 400;
    }
  }
}

.product {
  background: #fff;
  padding: 0 32px;
  .item {
    border-top: 2px solid #eeeeee;
    padding-top: 20px;
    margin-top: 20px;
    &:first-child {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }
    .img {
      width: 128px;
      height: 128px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 8px;
      }
    }

    .desc {
      flex: 1;
      margin-left: 18px;
      overflow: hidden;
      color: #000;

      .name {
        line-height: 35px;
        height: 70px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .price {
        margin-top: 14px;
        color: #999999;
        span {
          margin-right: 10px;
        }
      }

      .price-b {
        margin-top: 8px;

        b {
          margin-left: 6px;
        }
      }
    }
  }
}

.apply-content {
  margin: 0 32px;
  // padding: 32px;
  font-size: 24px;
  background: #fff;
  padding-bottom: 30px;

  .item {
    padding: 16px 0;
    font-size: 28px;
    .l {
      width: 160px;
      color: #666;
    }

    .c {
      flex: 1;

      .chiose {
        display: flex;
        align-items: center;
        margin-right: 20px;

        img {
          width: 48px;
          height: 48px;
          margin-right: 8px;
          display: block;
        }
      }

      .inp {
        // height: 64px;
        border: 2px solid #ddd;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 0 20px;
        font-weight: 500;
        line-height: 60px;

        textarea {
          width: 100%;
          border: none;
          background: none;
          line-height: 30px;
          height: 100px;
          margin-top: 10px;
          resize: none;
        }
      }

      .upload-view {
        width: 130px;
        height: 130px;
        border: 2px solid #ddd;
        border-radius: 8px;
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;

        .img {
          max-width: 130px;
          max-height: 130px;
          display: block;
        }

        .icon {
          display: block;
          width: 48px;
          height: 48px;
        }

        .del {
          width: 40px;
          height: 40px;
          background: rgb(213, 48, 62);
          border-radius: 20px;
          position: absolute;
          right: -10px;
          top: -10px;
          z-index: 1;

          img {
            width: 20px;
            height: 20px;
            display: block;
          }
        }
      }
    }
  }
}

.btn {
  position: fixed;
  bottom: 68px;
  left: 32px;
  right: 32px;
  text-align: center;
  line-height: 96px;
  height: 96px;
}

.product-bai {
  background: #fff;
  margin-top: 32px;
  padding-bottom: 32px;
}

.refund {
  background: #fff;
  margin-top: 32px;
  .t {
    padding-bottom: 16px;
  }
}
</style>